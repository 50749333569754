<div class="flex">
  <h2>Compor mensagem</h2>
  <button
    class="tertiary ml-auto"
    [routerLink]="[{ outlets: { modal: ['choose-template', ''] } }]"
    (click)="analyticsService.logEvent('user_content_choose_template_click')"
  >
    Usar template
  </button>
</div>

<!-- text -->
<textarea
  placeholder="Conteúdo"
  rows="10"
  [(ngModel)]="sendService.content.text"
  class="resize-none"
></textarea>

<!-- format with ai -->
<p class="flex items-center gap-3 text-black/60">
  @if (!formattingText) {
    <button
      class="ai p-1"
      [disabled]="
        !sendService.content.text ||
        formattedText() === sendService.content.text
      "
      (click)="
        formatText();
        analyticsService.logEvent('user_content_format-message_use_click')
      "
    >
      <span class="material-symbols-rounded"> auto_awesome </span>
    </button>
  } @else {
    <kor-spinner class="w-fit h-fit"></kor-spinner>
  }
  Melhore sua mensagem usando Inteligência Artificial.
</p>

<!-- variables -->
<hr />
<h2>Variáveis</h2>
<p>Clique para copiar e depois cole no campo de mensagem</p>
<div class="flex flex-wrap gap-1">
  <!-- random variables -->
  @for (variable of sendService.randomVariables; track variable) {
    <kor-tag
      class="cursor-pointer"
      [icon]="
        copiedVariable === variable.name ? 'check_circle' : 'content_copy'
      "
      label="{{ variable.name }} ({{ variable.values.length }})"
      [ngClass]="{
        'bg-green-600/10 border-green-600 text-green-600':
          copiedVariable === variable.name
      }"
      (click)="
        copyTextToClipboard(variable.name); copiedVariable = variable.name
      "
    ></kor-tag>
  }
  <!-- csv headers -->
  @if (sendService.csvHeaders) {
    @for (csvHeader of sendService.csvHeaders; track csvHeader) {
      <kor-tag
        [icon]="copiedVariable === csvHeader ? 'check_circle' : 'content_copy'"
        [label]="csvHeader"
        [ngClass]="{ success: copiedVariable === csvHeader }"
        (click)="
          copyTextToClipboard(csvHeader);
          copiedVariable = csvHeader;
          analyticsService.logEvent('user_content_variable_tag_click')
        "
      ></kor-tag>
    }
  }
</div>
<!-- media -->
<hr />
<h2>Mídia</h2>
<blu-media-input
  class="pb-1"
  [(media)]="sendService.content.media"
></blu-media-input>

<!-- unique view -->
<kor-checkbox
  [active]="sendService.content.viewOnce"
  (active-changed)="
    sendService.content.viewOnce = !sendService.content.viewOnce
  "
  label="Visualização única"
  [disabled]="!sendService.content.media.length"
></kor-checkbox>

<!-- message buttons -->
<hr />

<h2>Botões de resposta</h2>
<div class="mb-1 flex gap-2 items-center flex-wrap">
  <div class="flex items-center">
    <button
      class="tertiary rounded-r-none"
      (click)="analyticsService.logEvent('user_content_add-button_click')"
      [routerLink]="[{ outlets: { modal: ['add-button'] } }]"
      [disabled]="
        sendService.content.buttons && sendService.content.buttons.length > 2
      "
    >
      Adicionar botão
    </button>
    <button
      class="ai rounded-l-none p-1 border border-solid border-accent-100 hover:border-accent-200"
      (click)="generateButtons()"
      [disabled]="isLoading || !sendService.content.text"
      title="Gerar botões com I.A."
    >
      <span class="material-symbols-rounded"> auto_awesome </span>
    </button>
  </div>
  <div class="flex" *ngFor="let button of sendService.content.buttons">
    <button class="rounded-r-none w-fit bg-emerald-700 hover:bg-emerald-600">
      @if (button.url) {
        <span class="material-symbols-rounded">launch</span>
      }
      @if (button.number) {
        <span class="material-symbols-rounded">phone</span>
      }
      {{ button.body }}
    </button>
    <button
      class="rounded-l-none tertiary px-1"
      (click)="sendService.removeButton(button)"
    >
      <span class="material-symbols-rounded">clear</span>
    </button>
  </div>
</div>

<!-- poll -->
<!-- <ng-container>
  <hr />
  <h2>Enquete</h2>
  <kor-input
    label="Pergunta"
    [value]="sendService.content.poll.name"
    (input)="setPollName($event.target)"
  ></kor-input>
  <div class="poll-options">
    <kor-tag
      icon="add"
      class="cursor-pointer border-dashed"
      [ngClass]="{
        'opacity-20 pointer-events-none':
          !sendService.content.poll.name ||
          sendService.content.poll.options.length >= 5
      }"
      label="Adicionar resposta"
      (click)="analyticsService.logEvent('user_content_add-option_click')"
      [routerLink]="[{ outlets: { modal: ['add-poll-option'] } }]"
    ></kor-tag>

    @for (option of sendService.content.poll.options; track option) {
      <kor-tag
        removable
        class="cursor-pointer"
        [label]="option"
        (remove)="sendService.removePollButton(option)"
      >
      </kor-tag>
    }
    <br /><br />
  </div>
  @if (
    sendService.content.poll.name && sendService.content.poll.options.length < 2
  ) {
    <p class="flex gap-2">
      <kor-icon icon="error" color="rgb(var(--functional-red))"></kor-icon>
      Adicione pelo menos 2 respostas.
    </p>
  }

  <br /><br />
</ng-container> -->

<!-- footer -->
<div class="footer">
  <button
    (click)="
      previewCampaign();
      analyticsService.logEvent('user_content_preview-campaign_click')
    "
    class="secondary"
  >
    Testar comigo mesmo
  </button>
  <button
    [routerLink]="
      sendService.recipients.length ? '../trigger' : '../recipients'
    "
    [disabled]="
      !sendService.content.text &&
      !sendService.content.media.length &&
      (!sendService.content.poll.name ||
        sendService.content.poll.options.length < 2)
    "
    (click)="analyticsService.logEvent('user_content_confirm_click')"
  >
    Confirmar
  </button>
</div>

<!-- modal -->
<router-outlet name="modal"></router-outlet>
