<!-- splash -->
<div class="bg-accent-950 absolute top-0 left-0 w-full h-80 -z-10"></div>

<!-- header -->
<blu-header theme="dark"></blu-header>

<!-- announcements -->
@for (announcement of announcements; track $index) {
  <blu-announcement
    class="text-white"
    [announcement]="announcement"
  ></blu-announcement>
}

<!-- content -->
<router-outlet></router-outlet>

<!-- modal -->
<router-outlet name="modal"></router-outlet>
