<a href="https://home.blu.direct/" class="no-underline text-inherit">
  <h1 class="flex items-center gap-1">
    <img src="favicon.png" height="48" width="48" />blü
  </h1>
</a>

<!-- announcements -->
@for (announcement of announcements; track $index) {
  <blu-announcement
    class="w-80"
    [announcement]="announcement"
  ></blu-announcement>
}

<!-- card -->
<div class="card w-80 h-[480px] overflow-auto">
  <router-outlet></router-outlet>
</div>

2.9.0{{ isDev ? '-dev' : '' }}
