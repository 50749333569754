import { inject, Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { FirestoreCollections, IAnnouncement } from '@shared/interfaces';
import { SharedFirestoreService } from '@shared/services/firestore.service';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  private firestoreService = inject(SharedFirestoreService);

  async getAnnouncements(context: string): Promise<IAnnouncement[]> {
    const now = new Date();
    // get announcements starting before now and ending after now
    const announcements = await this.firestoreService.getDocs<IAnnouncement>(
      FirestoreCollections.Announcement,
      where('endsAt', '>', now),
      where('startsAt', '<', now),
      where('context', 'array-contains', context),
    );
    return announcements.docs.map((doc) => doc.data());
  }
}
