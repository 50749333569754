<kor-drawer label="Filtrar contatos" position="right" sticky visible>
  <kor-icon
    icon="close"
    slot="functions"
    button
    (click)="close.emit()"
  ></kor-icon>
  <!-- period -->
  <div class="filter-group">
    <span>Excluir contatos que <b>receberam disparos no período de:</b></span>
    <kor-radio-button
      label="Últimas 4 horas"
      (click)="contactListService.setTimeFilter(TimeFilters.FOUR_HOURS)"
      [active]="
        contactListService.activeFilters.time === TimeFilters.FOUR_HOURS
      "
    ></kor-radio-button>
    <kor-radio-button
      label="Último dia"
      (click)="contactListService.setTimeFilter(TimeFilters.ONE_DAY)"
      [active]="contactListService.activeFilters.time === TimeFilters.ONE_DAY"
    ></kor-radio-button>
    <kor-radio-button
      label="Últimos 7 dias"
      (click)="contactListService.setTimeFilter(TimeFilters.SEVEN_DAYS)"
      [active]="
        contactListService.activeFilters.time === TimeFilters.SEVEN_DAYS
      "
    ></kor-radio-button>
    <kor-radio-button
      label="Últimos 14 dias"
      (click)="contactListService.setTimeFilter(TimeFilters.FOURTEEN_DAYS)"
      [active]="
        contactListService.activeFilters.time === TimeFilters.FOURTEEN_DAYS
      "
    ></kor-radio-button>
    <kor-radio-button
      label="Mostrar todos"
      (click)="contactListService.setTimeFilter(TimeFilters.ALL)"
      [active]="contactListService.activeFilters.time === TimeFilters.ALL"
    ></kor-radio-button>
  </div>
  <!-- source -->
  <hr />
  <div class="filter-group">
    <span>Mostrar apenas <b>contatos importados por:</b> </span>
    <kor-radio-button
      label="Apenas contatos orgânicos"
      (click)="contactListService.setSourceFilter(SourceFilters.WHATSAPP)"
      [active]="
        contactListService.activeFilters.source === SourceFilters.WHATSAPP
      "
    ></kor-radio-button>
    <kor-radio-button
      label="Apenas contatos importados por lista"
      (click)="contactListService.setSourceFilter(SourceFilters.CSV)"
      [active]="contactListService.activeFilters.source === SourceFilters.CSV"
    ></kor-radio-button>
    <kor-radio-button
      label="Contatos importados por lista e orgânicos"
      (click)="contactListService.setSourceFilter(SourceFilters.ALL)"
      [active]="contactListService.activeFilters.source === SourceFilters.ALL"
    ></kor-radio-button>
  </div>
  <!-- deleted -->
  <hr />
  <div class="filter-group">
    <span>Mostrar <b>contatos deletados:</b> </span>
    <kor-radio-button
      label="Apenas contatos deletados"
      (click)="contactListService.setDeletedFilter(true)"
      [active]="contactListService.activeFilters.deleted === true"
    ></kor-radio-button>
    <kor-radio-button
      label="Apenas contatos ativos"
      (click)="contactListService.setDeletedFilter(false)"
      [active]="contactListService.activeFilters.deleted === false"
    ></kor-radio-button>
  </div>
  <!-- group -->
  <hr />
  <div class="filter-group">
    <span>Mostrar <b>grupos:</b> </span>
    <kor-radio-button
      label="Apenas contatos"
      (click)="contactListService.setGroupFilter(GroupFilters.CONTACT)"
      [active]="contactListService.activeFilters.group === GroupFilters.CONTACT"
    ></kor-radio-button>
    <kor-radio-button
      label="Apenas grupos"
      (click)="contactListService.setGroupFilter(GroupFilters.GROUP)"
      [active]="contactListService.activeFilters.group === GroupFilters.GROUP"
      [disabled]="sendService.hasButtons()"
    ></kor-radio-button>
    <kor-radio-button
      label="Contatos	e grupos"
      (click)="contactListService.setGroupFilter(GroupFilters.ALL)"
      [active]="contactListService.activeFilters.group === GroupFilters.ALL"
      [disabled]="sendService.hasButtons()"
    ></kor-radio-button>
  </div>
  <!-- international -->
  <hr />
  <div class="filter-group">
    <span>Mostrar <b>contatos internacionais:</b> </span>
    <kor-radio-button
      label="Apenas contatos nacionais"
      (click)="contactListService.setInternationalFilter(false)"
      [active]="contactListService.activeFilters.international === false"
    ></kor-radio-button>
    <kor-radio-button
      label="Contatos nacionais e internacionais"
      (click)="contactListService.setInternationalFilter(true)"
      [active]="contactListService.activeFilters.international === true"
    ></kor-radio-button>
  </div>
</kor-drawer>
