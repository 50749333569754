import { Component, Input } from '@angular/core';
import { IAnnouncement } from '@shared/interfaces';

@Component({
  selector: 'blu-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent {
  @Input() announcement!: IAnnouncement;
}
