import { Injectable } from '@angular/core';
import {
  collectionGroup,
  getDocs,
  limit,
  orderBy,
  query,
} from '@angular/fire/firestore';
import {
  collection,
  DocumentSnapshot,
  getCountFromServer,
  where,
} from '@firebase/firestore';
import { DataItem, ScaleType } from '@swimlane/ngx-charts';
import * as shape from 'd3-shape';
import { FirestoreCollections, IStats } from 'libs/shared/src/lib/interfaces';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  settings = {
    curve: shape.curveLinear,

    colorScheme: {
      name: 'blu',
      selectable: true,
      group: ScaleType.Ordinal,
      domain: ['#1372d8', '#00000050'],
    },
  };
  messages = {
    currentPeriod: {
      name: 'Mês atual',
      series: <DataItem[]>[],
      sum: 0,
    },
    previousPeriod: {
      name: 'Mês passado',
      series: <DataItem[]>[],
      sum: 0,
    },
  };
  campaigns = {
    currentPeriod: {
      name: 'Mês atual',
      series: <DataItem[]>[],
      sum: 0,
    },
    previousPeriod: {
      name: 'Mês passado',
      series: <DataItem[]>[],
      sum: 0,
    },
  };

  dataLoaded = new BehaviorSubject<string>('');

  constructor(
    private userService: UserService,
    private firestore: SharedFirestoreService,
  ) {
    const org = this.userService.organization();
    if (!org) return;
    // compare month to date with same period last month
    this.getData(org.uid);
  }

  private async getData(orgId: string) {
    const now = new Date();
    const currentDateStr = now.toISOString().split('T')[0];
    const allDates = generateDateStrings(currentDateStr);
    const currentMonth = now.getMonth() + 1;

    const fetchPromises = allDates.map(async (dateString) => {
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

      const dateMonth = parseInt(month);
      const period =
        dateMonth === currentMonth ? 'currentPeriod' : 'previousPeriod';

      const docSnap = await this.firestore.getDoc<IStats>(
        `organization/${orgId}/dailyStats`,
        dateString,
      );

      return {
        dateString,
        date,
        period: period as 'currentPeriod' | 'previousPeriod',
        messagesSentCount: docSnap?.messagesSentCount || 0,
        campaignsCount: docSnap?.campaignsCount || 0,
      };
    });

    const results = await Promise.all(fetchPromises);

    this.messages.currentPeriod.series = [];
    this.messages.previousPeriod.series = [];
    this.campaigns.currentPeriod.series = [];
    this.campaigns.previousPeriod.series = [];

    for (const result of results) {
      const { date, period, messagesSentCount, campaignsCount } = result;

      this.messages[period].series.push({
        name: date.getDate(),
        value: messagesSentCount,
      });

      this.campaigns[period].series.push({
        name: date.getDate(),
        value: campaignsCount,
      });
    }

    this.messages.currentPeriod.sum = this.sumArrayValue(
      this.messages.currentPeriod.series,
    );
    this.messages.previousPeriod.sum = this.sumArrayValue(
      this.messages.previousPeriod.series,
    );
    this.campaigns.currentPeriod.sum = this.sumArrayValue(
      this.campaigns.currentPeriod.series,
    );
    this.campaigns.previousPeriod.sum = this.sumArrayValue(
      this.campaigns.previousPeriod.series,
    );

    this.dataLoaded.next('messages');
  }

  async getRankedStatsForOrg(
    key: string,
    orgId: string,
    amount: number,
  ): Promise<(IStats | undefined)[]> {
    // model doc id
    const dateParts = new Date().toISOString().split('T')[0].split('-');
    const monthString = `${dateParts[0]}${dateParts[1]}`;
    // query collectionGroup monthlyStats
    const monthlyStatsQuery = query(
      collectionGroup(this.firestore.afs, 'monthlyStats'),
      where('uid', '==', monthString),
      where('scope', '==', 'user'),
      where('organization', '==', orgId),
      orderBy(key, 'desc'),
      limit(amount),
    );
    const monthlyStatsQuerySnap = await getDocs(monthlyStatsQuery);
    const response: (IStats | undefined)[] = [];
    monthlyStatsQuerySnap.docs.forEach((doc: DocumentSnapshot<IStats>) => {
      response.push(doc.data());
    });
    return response;
  }

  async getTodaysCampaigns(orgId: string): Promise<number> {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const campaignsQuery = query(
      collection(this.firestore.afs, FirestoreCollections.Campaign),
      where('organization', '==', orgId),
      where('createdAt', '>', today),
      where('status', '!=', 'paused'),
      orderBy('createdAt', 'desc'),
    );
    const campaignsQuerySnap = await getCountFromServer(campaignsQuery);
    return campaignsQuerySnap.data().count;
  }

  async getActiveWarmups(orgId: string): Promise<number> {
    const channelsQuery = query(
      collection(this.firestore.afs, FirestoreCollections.Channel),
      where('organization', '==', orgId),
      where('startedWarmingUpAt', '!=', null),
    );
    const snap = await getCountFromServer(channelsQuery);
    return snap.data().count;
  }

  async getActiveChannels(orgId: string): Promise<number> {
    const channelsQuery = query(
      collection(this.firestore.afs, FirestoreCollections.Channel),
      where('organization', '==', orgId),
      where('deletedAt', '==', null),
    );
    const snap = await getCountFromServer(channelsQuery);
    return snap.data().count;
  }

  sumArrayValue(arr: any[]): number {
    return arr.reduce(
      (accumulator, current) => accumulator + Number(current.value),
      0,
    );
  }
}

function generateDateStrings(dateStr: string): string[] {
  const currentDate = new Date(dateStr);
  const currentDay = currentDate.getDate();
  const result: string[] = [];
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  for (let day = currentDay; day >= 1; day--) {
    const date = new Date(year, month, day);
    result.push(formatDate(date));
  }
  const prevMonth = month === 0 ? 11 : month - 1;
  const prevMonthYear = month === 0 ? year - 1 : year;
  const lastDayOfPrevMonth = new Date(
    prevMonthYear,
    prevMonth + 1,
    0,
  ).getDate();
  const startDay = Math.min(currentDay, lastDayOfPrevMonth);
  for (let day = 1; day <= startDay; day++) {
    const date = new Date(prevMonthYear, prevMonth, day);
    result.push(formatDate(date));
  }
  return result;
}

function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}${month}${day}`;
}
