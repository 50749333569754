import { Component, Input, OnInit } from '@angular/core';
import { IUser } from 'libs/shared/src/lib/interfaces';
import { ChartService } from '@app/services/chart.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { UserService } from '@app/services/user.service';

@Component({
  selector: 'blu-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss'],
})
export class RankingComponent implements OnInit {
  @Input() selectedChart: 'messages' | 'campaigns' = 'messages';
  rankedUsers = {
    messages: <any>[],
    campaigns: <any>[],
  };

  constructor(
    private userService: UserService,
    private chartService: ChartService,
    private firestore: SharedFirestoreService,
  ) {}

  ngOnInit(): void {
    const org = this.userService.organization();
    if (!org) return;
    this.getRankedUsers(org.uid);
  }
  getRankedUsers(orgId: string): void {
    const dataSets: {
      name: 'messages' | 'campaigns';
      key: 'messagesSentCount' | 'campaignsCount';
    }[] = [
      { name: 'messages', key: 'messagesSentCount' },
      { name: 'campaigns', key: 'campaignsCount' },
    ];
    dataSets.forEach(async (set) => {
      // reset set
      this.rankedUsers[set.name] = [];
      // then top 5 stats for set
      const rankedStats = await this.chartService.getRankedStatsForOrg(
        set.key,
        orgId,
        3,
      );
      for (const stats of rankedStats) {
        const userId = stats?.user;
        if (!userId) return;
        const user = (await this.firestore.getDoc('user', userId)) as IUser;
        this.rankedUsers[set.name].push({
          name: user?.name,
          value: stats[set.key],
        });
      }
    });
  }
}
