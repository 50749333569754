<shared-modal [mobile]="sharedService.screenSize !== 'l'">
  <h2 slot="header">Adicionar botão</h2>
  <input
    placeholder="Texto do botão"
    [(ngModel)]="button.body"
    [maxLength]="20"
  />
  <kor-checkbox
    label="Usar como link para site ou ligação"
    [active]="showRedirect"
    (click)="$event.stopImmediatePropagation(); showRedirect = !showRedirect"
  ></kor-checkbox>
  @if (showRedirect) {
    <input placeholder="Site ou telefone" [(ngModel)]="button.url" />
    <span
      >Especifique o site ou número de telefone para ligação . (Exemplo:
      <b>https://www.google.com/</b> ou <b>31988887777</b>)
    </span>
  }
  <!-- footer -->
  <button
    slot="footer"
    label="Cancelar"
    class="secondary"
    (click)="
      sharedService.closeModal(route);
      analyticsService.logEvent('user_add-button_close_click')
    "
  >
    Cancelar
  </button>
  <button
    slot="footer"
    [disabled]="!button.body"
    (click)="
      createButton();
      sharedService.closeModal(route);
      analyticsService.logEvent('user_add-button_confirm_click')
    "
  >
    Adicionar
  </button>
</shared-modal>
