import { Component, inject, signal } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '@app/services/alert.service';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';
import { AnalyticsService } from '@shared/services/analytics.service';

@Component({
  selector: 'blu-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrl: './edit-account.component.scss',
  host: { class: 'modal-wrapper' },
})
export class EditAccountComponent {
  route = inject(ActivatedRoute);
  sharedService = inject(SharedService);
  userService = inject(UserService);
  app = inject(FirebaseApp);
  analyticsService = inject(AnalyticsService);
  alertService = inject(AlertService);

  functions = getFunctions(this.app);
  showDeletionConfirmation = signal(false);
  portalLinkLoading = signal(false);

  async createDashboardLink(): Promise<void> {
    this.portalLinkLoading.set(true);

    const billingPortalSession = httpsCallable(
      this.functions,
      'ext-firestore-stripe-payments-createPortalLink',
    );
    const res: any = await billingPortalSession({
      returnUrl: window.location.origin,
    });
    await window.open(res.data.url);
    this.portalLinkLoading.set(false);
  }

  async deleteAccount() {
    this.showDeletionConfirmation.set(true);
    this.analyticsService.logEvent('delete_account');
    const deleteAccount = httpsCallable(this.functions, 'deleteAccount');
    await deleteAccount();
  }

  alertDeleteAccount() {
    this.alertService.alert({
      title: 'Deletar Conta',
      message:
        'Tem certeza que deseja deletar sua conta? Todos os seus dados serão perdidos e não poderão ser recuperados.',
      confirmButtonText: 'Deletar',
      confirmCallback: () => this.deleteAccount(),
    });
  }
}
