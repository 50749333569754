<div class="label">
  <kor-icon
    icon="drag_indicator"
    class="cursor-move"
    draggable="true"
    (dragstart)="nodeDragStart($event)"
    (drag)="nodeDrag($event)"
    (dragend)="flowService.setNode(flowService.flow?.uid, node.uid, node)"
  ></kor-icon>

  <input
    class="text first-letter:col-start-1 row-start-1 !bg-transparent !border-none !p-0"
    contenteditable="true"
    (input)="onNodeTextChange($event, node)"
    [(ngModel)]="node.text"
    placeholder="Nova opção"
  />
  @if (node.uid !== flowService.flow?.startingNode) {
    <kor-icon
      button
      icon="delete"
      class="ml-auto"
      (click)="flowService.deleteNode(flowService.flow?.uid, node.uid)"
    ></kor-icon>
  }
</div>

<section class="flex flex-wrap gap-2 !pt-0">
  <!-- add media card -->
  <div
    class="h-12 w-12 border border-solid border-black/10 rounded-md relative flex hover:bg-black/5 transition-all"
  >
    <input
      class="p-0 absolute w-full h-full cursor-pointer opacity-0"
      type="file"
      accept="image/png, image/jpeg, audio/*, video/mp4"
      multiple
      (input)="uploadMedia($event)"
    />
    <span class="material-symbols-rounded m-auto text-black/40">
      attach_file_add
    </span>
  </div>
  <!-- media card -->
  @for (url of node.media; track url) {
    <div
      class="h-12 w-12 border border-solid border-black/10 rounded-md overflow-hidden flex place-content-center relative bg-black/5"
    >
      <!-- media thumb or icon -->
      @switch (mediaInputService.getFileType(url)) {
        @case ('image') {
          <img
            class="pointer-events-none object-cover bg-center"
            [src]="url"
            height="48"
            width="48"
          />
        }
        @case ('audio') {
          <span class="material-symbols-rounded m-auto text-black/40">
            mic
          </span>
        }
        @case ('video') {
          <span class="material-symbols-rounded m-auto text-black/40">
            videocam
          </span>
        }
        @default {
          <span class="material-symbols-rounded m-auto text-black/40">
            question_mark
          </span>
        }
      }
      <!-- delete icon -->
      <div
        class="absolute w-full h-full cursor-pointer opacity-0 hover:opacity-100 bg-black/75 text-white/90 z-10 flex items-center justify-center transition-all"
      >
        <span class="material-symbols-rounded m-auto" (click)="removeUrl(url)">
          delete
        </span>
      </div>
    </div>
  }
</section>

@for (option of node.responseOptions; track option) {
  <div
    class="response"
    [ngClass]="{
      'bg-black/5': node.responseOptions.length === 1 && !option.label
    }"
  >
    @if (node.responseOptions.length === 1 && !option.label) {
      <div class="w-full">
        <p class="text-center italic text-black/40">Resposta Aberta</p>
      </div>
    } @else {
      <div class="index" (click)="deleteOption(node, option)">
        <b class="index-number">{{ option.index + 1 }}</b>
        <kor-icon button icon="delete" class="delete-response-icon"></kor-icon>
      </div>
      <div class="grid w-full">
        <input
          class="col-start-1 row-start-1 !bg-transparent !border-none !p-0"
          (input)="onOptionLabelChange($event, node, option)"
          [(ngModel)]="option.label"
          [maxlength]="20"
          placeholder="Nova resposta"
        />
      </div>
    }
    <div
      draggable="true"
      class="drag-handle"
      (dragstart)="
        optionDragStart.emit({
          event: $event,
          nodeId: node.uid,
          optionId: option.index
        })
      "
      (dragend)="optionDragEnd.emit($event)"
      (click)="unsetActionNode(node, option)"
      [ngClass]="{
        filled: option.actionNode,
        outline:
          flowService.draggedResponseOption?.parentNodeId === node.uid &&
          flowService.draggedResponseOption?.index === option.index
      }"
      id="o_{{ node.uid }}_{{ option.index }}"
    ></div>
  </div>
}

<button
  [disabled]="node.responseOptions.length >= 3"
  class="response add"
  (click)="addOption(node)"
>
  Adicionar resposta
</button>
