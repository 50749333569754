import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastMessage, ToastService } from '@shared/services/toast.service';
import {
  DatabaseCollections,
  IContact,
  MessageContent,
} from 'libs/shared/src/lib/interfaces';
import { SharedBluApiService } from 'libs/shared/src/lib/services/blu-api.service';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { randomVariables } from './send.service';

function generateFirestoreUID(length = 20) {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let uid = '';
  uid += chars[Math.floor(Math.random() * 52)];
  for (let i = 1; i < length; i++) {
    uid += chars[Math.floor(Math.random() * chars.length)];
  }
  return uid;
}

@Injectable({
  providedIn: 'root',
})
export class BluApiService {
  qr$ = new Subject<string>();
  loadingIndicator$ = new BehaviorSubject<boolean>(false);
  progress$ = new Subject<number>();
  eventSource: EventSource | null = null;
  qrError = false;
  serverId = generateFirestoreUID();

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private sharedBluApi: SharedBluApiService,
    private toastService: ToastService,
  ) {}

  randomizeServerId() {
    this.serverId = generateFirestoreUID();
  }

  async getGuestRecipients(
    orgId: string,
    createdAfter?: Date,
    countOnly?: boolean,
  ) {
    let url =
      this.sharedBluApi.buildUrl(DatabaseCollections.Recipients) +
      `/export?organization=${orgId}`;
    if (createdAfter) {
      url += `&createdAfter=${createdAfter.toISOString().split('T')[0]}`;
    }
    if (countOnly) url += `&countOnly=true`;

    const options: { [key: string]: any } = {
      headers: await this.sharedBluApi.buildHeaders(),
    };
    if (!countOnly) options['responseType'] = 'blob';

    return firstValueFrom(this.http.get(url, options));
  }

  async getContactsByUserId(
    userId: string,
    excludeGroups = false,
  ): Promise<IContact[]> {
    const url =
      this.sharedBluApi.buildUrl(DatabaseCollections.Recipients) +
      `?user=${userId}&excludeGroups=${excludeGroups}&includeFromPhone=true&includeInternational=true`;
    const headers = await this.sharedBluApi.buildHeaders();
    return firstValueFrom(this.http.get<IContact[]>(url, { headers }));
  }

  async updateContact(
    whatsappId: string,
    userId: string,
    body: { [key: string]: any },
  ) {
    const url =
      this.sharedBluApi.buildUrl(DatabaseCollections.Recipients) +
      `?whatsappId=${whatsappId}&user=${userId}`;
    const headers = await this.sharedBluApi.buildHeaders();
    await firstValueFrom(this.http.patch(url, body, { headers }));
  }

  initializeQR(onSuccess: () => void) {
    if (this.eventSource) return;
    this.randomizeServerId();
    const url =
      this.sharedBluApi.buildUrl(DatabaseCollections.Sessions, 'add-sse') +
      `?s=${this.serverId}`;
    this.eventSource = new EventSource(url);
    this.eventSource.addEventListener('message', async (event) => {
      const data = JSON.parse(event.data);
      if (data.qr) {
        this.qr$.next(data.qr);
        this.loadingIndicator$.next(false);
      } else if (data.isNewLogin) this.loadingIndicator$.next(true);
      else if (data.token) {
        await this.auth.signInWithCustomToken(data.token);
        // HOTFIX: give some time for the user to be queried from firestore
        await new Promise((resolve) => setTimeout(resolve, 1000));
        onSuccess();
        this.qr$.complete();
        this.loadingIndicator$.complete();
        this.closeQRConnection();
      } else if (data.error) {
        this.onError(data.error);
        this.closeQRConnection();
      }
    });
    this.eventSource.addEventListener('error', (event) => {
      this.qrError = true;
      this.closeQRConnection();
      this.loadingIndicator$.complete();
      this.qr$.complete();
    });
  }

  initializeChannelQR(uid: string) {
    if (this.eventSource) return;
    const url = this.sharedBluApi.buildUrl(
      DatabaseCollections.Sessions,
      `add-bot-sse?channel=${uid}&s=${uid}`,
    );
    this.eventSource = new EventSource(url);
    this.eventSource.addEventListener('message', async (event) => {
      const data = JSON.parse(event.data);
      if (data.qr) {
        this.qr$.next(data.qr);
        this.loadingIndicator$.next(false);
      } else if (data.isNewLogin) this.loadingIndicator$.next(true);
      else if (data.error) {
        this.onError(data.error);
        this.closeQRConnection();
      } else if (data.message && data.message === 'ok') {
        this.closeQRConnection();
        this.loadingIndicator$.complete();
        this.qr$.complete();
      }
    });
    this.eventSource.addEventListener('error', (event) => {
      this.qrError = true;
      this.closeQRConnection();
      this.loadingIndicator$.complete();
      this.qr$.complete();
    });
  }

  closeQRConnection() {
    this.eventSource?.close();
    this.eventSource = null;
  }

  initializeTemplateQR(templateId: string, onSuccess: () => void): void {
    if (this.eventSource) return;
    this.randomizeServerId();
    const url =
      this.sharedBluApi.buildUrl(
        DatabaseCollections.Sessions,
        'add-template-sse',
      ) + `?s=${this.serverId}&template=${templateId}`;
    this.eventSource = new EventSource(url);
    this.eventSource.addEventListener('message', async (event) => {
      const data = JSON.parse(event.data);
      if (data.qr) this.qr$.next(data.qr);
      else if (data.isNewLogin) this.loadingIndicator$.next(true);
      else if (data.token) {
        await this.auth.signInWithCustomToken(data.token);
        // HOTFIX: give some time for the user to be queried from firestore
        await new Promise((resolve) => setTimeout(resolve, 1000));
        onSuccess();
        this.qr$.complete();
        this.closeQRConnection();
        this.loadingIndicator$.complete();
      } else if (data.error) {
        this.onError(data.error);
        this.closeQRConnection();
      }
    });
    this.eventSource.addEventListener('error', (event) => {
      this.closeQRConnection();
      this.loadingIndicator$.complete();
      this.qr$.complete();
    });
  }

  startCampaign(campaignId: string) {
    const url =
      this.sharedBluApi.buildUrl(
        DatabaseCollections.Campaigns,
        campaignId,
        'start',
      ) + `?s=${this.serverId}`;
    const eventSource = new EventSource(url);
    let hasStarted = false;
    eventSource.addEventListener('message', (event) => {
      hasStarted = true;
      const data = JSON.parse(event.data);
      if (Number.isInteger(+data)) this.progress$.next(data);
    });
    eventSource.addEventListener('error', (_) => {
      if (!hasStarted) {
        this.toastService.createToast({
          title: 'Erro',
          message: ToastMessage.StartCampaignError,
          icon: 'error',
          iconColor: 'rgb(var(--functional-red))',
        });
      }
      this.progress$.complete();
      eventSource.close();
    });
  }

  async previewCampaign(content: MessageContent) {
    function replaceRandomVariables(text?: string): string | undefined {
      if (!text) return text;
      const textVariables = text?.match(/{{([^}]+)\}}/g);
      if (!textVariables) return text;

      for (const variable of textVariables) {
        const variableName = variable.replace(/{{|}}/g, '');
        const varObject = randomVariables.find(
          (randomVar) => randomVar.name === variableName,
        );
        if (!varObject) continue;
        const randomIndex = Math.floor(Math.random() * varObject.values.length);
        const randomValue = varObject.values[randomIndex];
        text = text.replace(variable, randomValue);
      }
      return text;
    }

    const url =
      this.sharedBluApi.buildUrl(DatabaseCollections.Campaigns, 'preview') +
      `?s=${this.serverId}`;
    const headers = await this.sharedBluApi.buildHeaders();
    const body = {
      campaign: {
        text: replaceRandomVariables(content.text),
        media: content.media,
        poll: content.poll,
        viewOnce: content.viewOnce,
        buttons: content.buttons,
      },
    };
    return firstValueFrom(
      this.http.post(url, body, {
        headers,
        responseType: 'text',
      }),
    );
  }

  async bulkDeleteContacts(user: string, contacts: string[]) {
    const url =
      this.sharedBluApi.buildUrl(DatabaseCollections.Recipients, 'bulk') +
      `?user=${user}`;
    const headers = await this.sharedBluApi.buildHeaders();
    const body = contacts;
    await firstValueFrom(this.http.delete(url, { headers, body }));
  }

  async bulkUndeleteContacts(user: string, contacts: string[]) {
    const url =
      this.sharedBluApi.buildUrl(
        DatabaseCollections.Recipients,
        'bulk/undelete',
      ) + `?user=${user}`;
    const headers = await this.sharedBluApi.buildHeaders();
    const body = contacts;
    await firstValueFrom(this.http.patch(url, body, { headers }));
  }

  async onError(errorType: string) {
    this.router.navigateByUrl(`error/${errorType}`);
  }
}
