import { Component, OnInit } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import {
  FirestoreCollections,
  ICampaign,
} from 'libs/shared/src/lib/interfaces';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { SendService } from '../../../services/send.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'blu-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  host: { class: 'modal-wrapper' },
})
export class HistoryComponent implements OnInit {
  campaigns: ICampaign[] = [];
  constructor(
    public sharedService: SharedService,
    private userService: UserService,
    private firestore: SharedFirestoreService,
    private sendService: SendService,
    private route: ActivatedRoute,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.getCampaigns();
  }

  // get campaigns from user
  private async getCampaigns(): Promise<void> {
    // reset before fetching
    this.campaigns = [];
    // fetch
    const userUid = this.userService.user()?.uid;
    if (!userUid) return;
    const campaignSnap = await this.firestore.getDocs<ICampaign>(
      FirestoreCollections.Campaign,
      where('sentBy', '==', userUid),
      orderBy('createdAt', 'desc'),
    );
    campaignSnap.docs.forEach((campaign) => {
      const campaignData = campaign.data();
      this.campaigns.push(campaignData);
    });
  }

  copyCampaign(campaign: ICampaign): void {
    this.sendService.setRecipients(campaign.recipients);
    this.sendService.content = {
      text: campaign.text,
      media: campaign.media,
      poll: {
        name: campaign.poll?.name || null,
        options: campaign.poll?.options || [],
      },
      viewOnce: campaign.viewOnce || false,
      buttons: this.sendService.content.buttons,
    };
    this.sharedService.closeModal(this.route);
  }
}
