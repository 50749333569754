import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataItem } from '@swimlane/ngx-charts';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { ChartService } from '@app/services/chart.service';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';

@Component({
  selector: 'blu-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class SendStatsComponent implements OnInit {
  sharedService = inject(SharedService);
  chartService = inject(ChartService);
  router = inject(Router);
  route = inject(ActivatedRoute);
  analyticsService = inject(AnalyticsService);
  userService = inject(UserService);

  loading = true;
  today = new Date();
  selectedChart: 'messages' | 'campaigns' = 'messages';
  chartData?: any[];

  kpis = [
    {
      label: 'Disparos hoje',
      icon: 'campaign',
      value: 0,
      routerLink: '/admin/send/campaigns',
    },
    {
      label: 'Chips em aquecimento',
      icon: 'local_fire_department',
      value: 0,
      routerLink: '/admin/warmup',
    },
    {
      label: 'Canais ativos',
      icon: 'phonelink_ring',
      value: 0,
      routerLink: '/admin/chat/channels',
    },
  ];

  async ngOnInit() {
    this.chartService.dataLoaded.subscribe((type) => {
      if (type !== this.selectedChart) return;
      this.loading = false;
      this.getChartData(type);
    });
    // get kpis
    const orgId = this.userService.organization()?.uid;
    if (!orgId) return;
    this.kpis[0].value = await this.chartService.getTodaysCampaigns(orgId);
    this.kpis[1].value = await this.chartService.getActiveWarmups(orgId);
    this.kpis[2].value = await this.chartService.getActiveChannels(orgId);
  }

  selectChart(name: 'messages' | 'campaigns') {
    this.selectedChart = name;
    this.getChartData(name);
  }

  getChartData(name: 'messages' | 'campaigns'): void {
    switch (name) {
      case 'messages':
        this.chartData = [
          this.chartService.messages.currentPeriod,
          this.chartService.messages.previousPeriod,
        ];
        console.log(this.chartData);
        return;
      case 'campaigns':
        // compare current period and previous period on chart data
        this.chartData = [
          this.chartService.campaigns.currentPeriod,
          this.chartService.campaigns.previousPeriod,
        ];
        return;
      default:
        return;
    }
  }

  formatDateTick(dateString: string): string {
    const dateParts = dateString.split('/');
    return `${dateParts[0]}/${dateParts[1]}`;
  }
}
