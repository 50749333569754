<!-- ranking -->
<div class="flex flex-col">
  @for (user of rankedUsers[selectedChart]; track user; let i = $index) {
    <div
      class="flex gap-2 py-3 flex-1 items-center border-t border-solid border-black/5 border-0"
    >
      <img
        height="24"
        width="24"
        ngSrc="assets/icons/rank_{{ i + 1 }}.png"
        class="h-6 w-6"
      />
      <span class="flex-1">{{ user.name }}</span>
      <h2>{{ user.value }}</h2>
    </div>
  }
</div>
