import { Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { AlertService } from '@app/services/alert.service';
import { UserService } from '@app/services/user.service';
import { MediaInputService } from '@shared/services/media-input.service';
import { INode, IResponseOption } from 'libs/shared/src/lib/interfaces';
import { FlowService } from '../../../../../../services/flow.service';

@Component({
  selector: 'blu-flow-node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss'],
  host: { class: 'card' },
})
export class NodeComponent {
  @Output() optionDragStart = new EventEmitter<{
    event: DragEvent;
    nodeId: string;
    optionId: number;
  }>();
  @Output() optionDragEnd = new EventEmitter<DragEvent>();
  @Input({ required: true }) node!: INode;

  flowService = inject(FlowService);
  mediaInputService = inject(MediaInputService);
  userService = inject(UserService);
  alertService = inject(AlertService);

  dragStartCoords = {
    x: 0,
    y: 0,
  };
  mediaPromises: Promise<string>[] = [];

  nodeDragStart(e: DragEvent) {
    const img = new Image();
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    e.dataTransfer?.setDragImage(img, 0, 0);
    this.dragStartCoords = {
      x: e.clientX,
      y: e.clientY,
    };
  }

  nodeDrag(e: DragEvent) {
    const gridSize = 8;
    if (!this.node?.coords || e.clientX === 0) return;
    const xDelta = e.clientX - this.dragStartCoords.x;
    const yDelta = e.clientY - this.dragStartCoords.y;
    if (xDelta > gridSize || xDelta < gridSize * -1) {
      if (xDelta < -1 && this.node.coords.x <= 0) return;
      const multiplier = xDelta < -1 ? -1 : 1;
      this.dragStartCoords.x = this.dragStartCoords.x + gridSize * multiplier;
      this.node.coords.x += 1 * multiplier;
    }
    if (yDelta > gridSize || yDelta < gridSize * -1) {
      if (yDelta < -1 && this.node.coords.y <= 0) return;
      const multiplier = yDelta < -1 ? -1 : 1;
      this.dragStartCoords.y = this.dragStartCoords.y + gridSize * multiplier;
      this.node.coords.y += 1 * multiplier;
    }
  }

  deleteOption(node: INode, option: IResponseOption): void {
    const flowId = this.flowService.flow?.uid;
    if (!flowId) throw new Error('No flow id');
    if (this.node.responseOptions.length === 1) {
      this.node.responseOptions[0].label = '';
      this.flowService.setNode(this.flowService.flow?.uid, node.uid, node);
      return;
    }
    this.flowService.deleteResponseOption(flowId, node, option.index);
  }

  addOption(node: INode) {
    const flowId = this.flowService.flow?.uid;
    if (!flowId) throw new Error('No flow id');

    if (
      this.node.responseOptions.length === 1 &&
      !this.node.responseOptions[0].label
    ) {
      this.node.responseOptions[0].label = 'Nova resposta';
      this.flowService.setNode(this.flowService.flow?.uid, node.uid, node);
      return;
    }

    this.flowService.addResponseOption(
      flowId,
      node.uid,
      node.responseOptions.length,
    );
  }

  onOptionLabelChange(e: Event, node: INode, option: IResponseOption): void {
    option.label = (e.target as HTMLInputElement).value;
    this.flowService.setNode(this.flowService.flow?.uid, node.uid, node);
  }

  onNodeTextChange(e: Event, node: INode): void {
    node.text = (e.target as HTMLInputElement).value;
    this.flowService.setNode(this.flowService.flow?.uid, node.uid, node);
  }

  unsetActionNode(node: INode, option: IResponseOption): void {
    if (!this.flowService.flow?.uid) return;
    option.actionNode = null;
    this.flowService.setNode(this.flowService.flow?.uid, node.uid, node);
  }

  async uploadMedia(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = Array.from(target.files || []);
    if (!files || !files.length) return;
    const orgId = this.userService.organization()?.uid;
    if (!orgId) throw new Error('No organization id');
    this.mediaPromises = this.mediaInputService.uploadFiles(
      orgId,
      'flow',
      files,
    );
    const media = await Promise.allSettled(this.mediaPromises);
    this.mediaPromises = [];
    this.saveMedia(media);
  }

  saveMedia(media: PromiseSettledResult<string>[]) {
    for (const m of media) {
      if (m.status === 'fulfilled') {
        this.node.media = this.node.media || [];
        this.node.media.push(m.value);
      } else {
        this.alertService.alert({
          title: 'Erro ao salvar arquivo',
          message: m.reason,
        });
      }
    }
    this.flowService.setNode(
      this.flowService.flow?.uid,
      this.node.uid,
      this.node,
    );
  }

  removeUrl(url: string) {
    this.mediaInputService.deleteFile(url);
    this.node.media = this.node.media?.filter((m) => m !== url);
    this.flowService.setNode(
      this.flowService.flow?.uid,
      this.node.uid,
      this.node,
    );
  }
}
