<!-- if user has created campaigns -->
@if (userService.onboardingStepVisible('createdCampaign')) {
  <!-- chart -->
  <div class="card min-h-[480px]">
    <h2 class="flex items-center gap-3">
      <span class="material-symbols-rounded">insights</span>Mês atual ({{
        today | date: 'LLLL' : 'pt-BR'
      }})
    </h2>
    @if (!loading && chartData) {
      <div
        class="no-scrollbar flex gap-2 my-0 -mx-5 py-[1px] px-5 overflow-auto calc(100% + calc(var(--gap-xl) * 2)) box-border"
      >
        <blu-kpi
          [active]="selectedChart === 'messages'"
          (click)="
            selectChart('messages');
            analyticsService.logEvent('admin_messages_kpi_click')
          "
          [button]="true"
          [value]="chartService.messages.currentPeriod.sum"
          [label]="'Mensagens'"
          [trend]="
            chartService.messages.currentPeriod.sum -
            chartService.messages.previousPeriod.sum
          "
        ></blu-kpi>
        <blu-kpi
          [active]="selectedChart === 'campaigns'"
          (click)="
            selectChart('campaigns');
            analyticsService.logEvent('admin_campaigns_kpi_click')
          "
          [button]="true"
          [value]="chartService.campaigns.currentPeriod.sum"
          [label]="'Disparos'"
          [trend]="
            chartService.campaigns.currentPeriod.sum -
            chartService.campaigns.previousPeriod.sum
          "
        ></blu-kpi>
      </div>
      <div class="h-[320px] w-full">
        <ngx-charts-line-chart
          [xAxis]="true"
          [yAxis]="true"
          [results]="chartData"
          [animations]="false"
          [curve]="chartService.settings.curve"
          [scheme]="chartService.settings.colorScheme"
        >
        </ngx-charts-line-chart>
      </div>
    } @else {
      <!-- else, show empty state -->
      <div
        class="flex flex-col gap-4 items-center justify-center m-auto h-[420px]"
      >
        <img
          class="w-auto max-w-full object-contain"
          src="assets/images/chart_empty-state.png"
          height="200"
        />
        <p>Nenhum disparo realizado até o momento</p>
      </div>
    }
    <!-- ranking -->
    <blu-ranking [selectedChart]="selectedChart"></blu-ranking>
  </div>

  <!-- kpis -->
  <div class="grid gap-2 grid-cols-1 md:grid-cols-3">
    @for (kpi of kpis; track kpi.value) {
      <div
        class="card !gap-2 items-center cursor-pointer hover:bg-black/5"
        [routerLink]="kpi.routerLink"
      >
        <h2 class="text-4xl flex items-center gap-2">
          <span
            class="material-symbols-rounded p-2 rounded-full bg-accent-600/10 text-accent-600"
            >{{ kpi.icon }}</span
          >{{ kpi.value || '–' }}
        </h2>
        <span class="text-center">{{ kpi.label }}</span>
      </div>
    }
  </div>
}

<!-- placeholder -->
@else {
  <div class="card h-full items-center justify-center">
    <div class="max-w-80 text-center flex flex-col items-center gap-4">
      <h1>👋 Novo no blü?</h1>
      <p class="leading-snug text-black/60">
        Antes de começar a explorar, que tal conferir nosso tutorial para tirar
        o máximo proveito do painel de administradores?
      </p>

      <a
        href="https://home.blu.direct/blog/visao-geral-painel-administrador"
        target="_blank"
        class="no-underline"
        (click)="analyticsService.logEvent('admin_help_dashboard_click')"
      >
        <button class="secondary">Saiba mais</button>
      </a>
    </div>
  </div>
}

<!-- modal -->
<router-outlet name="modal"></router-outlet>
