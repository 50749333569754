<div
  class="card"
  [style.height]="sharedService.screenSize === 'l' ? '480px' : '100%'"
  [style.width]="sharedService.screenSize === 'l' ? '480px' : '100%'"
>
  <!-- header -->
  <header class="header">
    <h2>Editar conta</h2>
    <kor-icon
      button
      icon="close"
      (click)="sharedService.closeModal(route)"
    ></kor-icon>
  </header>

  @if (portalLinkLoading()) {
    <kor-spinner></kor-spinner>
  } @else {
    <section class="w-full flex flex-col gap-4 h-full">
      <div>
        <p>{{ userService.organization()?.name }}</p>
      </div>
      <div
        class="blu-menu-item w-full"
        (click)="
          createDashboardLink();
          analyticsService.logEvent('header_subscription-tag_click')
        "
      >
        Alterar plano ({{ userService.organization()?.subscription?.plan }})
      </div>

      <!-- <div class="mt-8">
      <kor-button
        slot="footer"
        color="tertiary"
        (click)="
          alertDeleteAccount();
          analyticsService.logEvent('header_delete-account_click')
        "
        label="Deletar conta e dados"
        [disabled]="showDeletionConfirmation()"
      >
      </kor-button>
      @if (showDeletionConfirmation()) {
        <div class="mt-4">
          <p class="text-red-500">
            Sua solicitação foi enviada com sucesso e será processada em até 7
            dias úteis.
          </p>
        </div>
      }
    </div> -->
    </section>
  }
</div>
