import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAnnouncement } from 'libs/shared/src/lib/interfaces';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { UserService } from '../../services/user.service';
import { AnnouncementService } from '@app/services/announcement.service';

@Component({
  selector: 'blu-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  announcementService = inject(AnnouncementService);
  announcements: IAnnouncement[] = [];
  analyticsService = inject(AnalyticsService);
  private router = inject(Router);
  private userService = inject(UserService);

  async ngOnInit() {
    const user = this.userService.user();
    // get user announcements
    this.announcements =
      await this.announcementService.getAnnouncements('user');
    if (!user) {
      this.router.navigateByUrl('/sign-in');
      return;
    }
  }
}
