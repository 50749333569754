import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnnouncementService } from '@app/services/announcement.service';
import { IAnnouncement } from '@shared/interfaces';

@Component({
  selector: 'blu-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  isDev = false;
  announcementService = inject(AnnouncementService);
  announcements: IAnnouncement[] = [];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    // get sign in announcements
    this.announcements =
      await this.announcementService.getAnnouncements('sign-in');
    this.route.queryParams.subscribe((params) => {
      this.isDev = 'dev' in params;
    });
  }
}
