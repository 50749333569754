<section class="images">
  @if (!readonly) {
    <div class="card button add">
      <input
        type="file"
        [accept]="inputAccept"
        multiple
        (input)="
          uploadMedia($event);
          analyticsService.logEvent('user_content_media_upload')
        "
      />
      <kor-icon size="l" icon="add" color="var(--text-2)"></kor-icon>
      <p class="leading-snug">{{ inputText }}</p>
    </div>
  }
  @for (singleMedia of mediaBlob; track media) {
    <div class="card image">
      @if (!readonly) {
        <kor-icon
          button
          size="l"
          icon="delete"
          (click)="
            removeMedia(singleMedia);
            analyticsService.logEvent('user_content_remove_media_click')
          "
        ></kor-icon>
      }
      <div class="media-card" [ngSwitch]="true">
        <!-- image preview -->
        <img
          *ngSwitchCase="isImage(singleMedia)"
          [src]="singleMedia.url"
          alt="A preview of the media selected by the user"
        />

        <!-- video icon -->
        <kor-icon
          *ngSwitchCase="isVideo(singleMedia)"
          icon="videocam"
          size="l"
        ></kor-icon>

        <!-- audio icon -->
        <kor-icon
          *ngSwitchCase="isAudio(singleMedia)"
          icon="mic"
          size="l"
        ></kor-icon>

        <!-- loading icon -->
        <kor-spinner *ngSwitchDefault></kor-spinner>
      </div>
    </div>
  }
</section>
