<div class="card min-h-full">
  <!-- templates -->
  <h2 class="flex items-center gap-3">
    <span class="material-symbols-rounded"> List </span>Disparos recentes
  </h2>

  @if (campaigns.length > 0) {
    <kor-table columns="1fr auto auto" class="max-h-none">
      @for (campaign of campaigns; track campaign) {
        <kor-table-row
          [routerLink]="[{ outlets: { modal: ['campaign', campaign.id] } }]"
        >
          <kor-table-cell>
            <div class="flex gap-2 items-center">
              <kor-icon
                [icon]="
                  sharedService.getCampaignStatusIcon(campaign.status).icon
                "
                [color]="
                  sharedService.getCampaignStatusIcon(campaign.status).color
                "
                [title]="campaign.id"
              ></kor-icon>
              <p class="flex flex-col">
                <b>{{ campaign.userName }}</b>
                {{ campaign.messagesSent }}/{{ campaign.messagesTotal }}
                mensagens
              </p>
            </div>
          </kor-table-cell>
          <kor-table-cell>
            {{ campaign.createdAt.toDate() | date: 'HH:mm' }}
          </kor-table-cell>
        </kor-table-row>
      }
    </kor-table>
  } @else {
    <!-- show empty state -->
    <div
      class="max-w-80 min-h-80 text-center flex flex-col items-center gap-4 justify-center m-auto"
    >
      <h1>Nenhum disparo realizado hoje</h1>
      <p class="leading-snug text-black/60">
        Realize um disparo para ver o status do envio e detalhe do conteúdo
        nesta página.
      </p>
    </div>
  }
</div>

<!-- modal -->
<router-outlet name="modal"></router-outlet>
