<div class="card min-h-full">
  <!-- templates -->
  <h2 class="flex items-center gap-3">
    <span class="material-symbols-rounded"> wysiwyg </span>
    Modelos de disparo ({{ templates.length }})
    <button
      [routerLink]="[{ outlets: { modal: ['add-template', ''] } }]"
      class="ml-auto tertiary !px-1 md:!px-3"
      (click)="analyticsService.logEvent('admin_add_template_button_click')"
    >
      <span class="material-symbols-rounded m-auto text-black/60"> add </span>
      @if (sharedService.screenSize !== 's') {
        Adicionar
      }
    </button>
  </h2>

  @if (templates.length > 0) {
    <!-- body -->
    <p class="text-balance text-black/60">
      Modelos de disparo podem ser usados para harmonizar as mensagens enviadas
      e poupar tempo do seu time.
      <!-- TODO: add tutorial link -->
    </p>
    <kor-table>
      <kor-table-row slot="header" class="flex">
        <kor-table-cell head class="flex-1"> Nome </kor-table-cell>
        <kor-table-cell head alignment="right">Leads (30 dias)</kor-table-cell>
      </kor-table-row>
      @for (template of templates; track template.uid) {
        <kor-table-row
          class="flex"
          [routerLink]="[
            { outlets: { modal: ['add-template', template.uid] } }
          ]"
          (click)="analyticsService.logEvent('admin_template_row_click')"
        >
          <kor-table-cell class="flex-1"> {{ template.name }}</kor-table-cell>
          <kor-table-cell alignment="right">
            @if (template.guestsCount | async) {
              <kor-tag
                class="button"
                icon="group"
                [label]="template.guestsCount"
                (click)="$event.stopPropagation()"
              ></kor-tag>
            }
          </kor-table-cell>
        </kor-table-row>
      }
    </kor-table>

    <section class="footer">
      @if (!loadingGuestRecipientsCount) {
        <!-- count -->
        <p class="mr-auto">
          {{ guestRecipientsCount || 0 }} leads gerados desde
          {{
            (
              userService.organization()?.lastGuestRecipientsExportDate ||
              userService.organization()?.createdAt
            )?.toDate() | date: 'MMM d YYYY'
          }}
        </p>
      } @else {
        <kor-spinner class="w-fit"></kor-spinner>
        <p class="mr-auto">Carregando leads gerados</p>
      }

      <!-- csv -->
      @if (!loadingGuestRecipientsCsv) {
        <kor-button
          label="Exportar leads"
          [disabled]="!guestRecipientsCount || guestRecipientsCount === 0"
          (click)="
            getGuestRecipientsCsv();
            analyticsService.logEvent('admin_export_leads_button_click')
          "
        ></kor-button>
      } @else {
        <kor-spinner class="w-fit"></kor-spinner>
      }
    </section>
  } @else {
    <!-- empty state -->
    <div
      class="max-w-80 min-h-80 text-center flex flex-col items-center gap-4 justify-center m-auto"
    >
      <h1>Crie modelos de disparo</h1>
      <p class="leading-snug text-black/60">
        Modelos de disparo podem ser usados para harmonizar as mensagens
        enviadas e poupar tempo do seu time.
      </p>
      <!-- TODO: add tutorial link -->
    </div>
  }

  <router-outlet name="modal"></router-outlet>
</div>
