import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SendService } from '@app/services/send.service';
import { SharedService } from '@app/services/shared.service';
import { IWAButton } from '@shared/interfaces';
import { AnalyticsService } from '@shared/services/analytics.service';

@Component({
  selector: 'blu-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss'],
  host: { class: 'modal-wrapper' },
})
export class AddButtonComponent {
  showRedirect = false;
  button: IWAButton = {
    body: null,
    url: null,
  };

  constructor(
    public route: ActivatedRoute,
    public analyticsService: AnalyticsService,
    public sharedService: SharedService,
    private sendService: SendService,
  ) {}

  setButton(key: 'body' | 'url', e: Event): void {
    let value: string | null = (e.target as HTMLInputElement).value;
    if (value === '') value = null;
    if (key === 'body' && value && value.length > 25)
      (e.target as HTMLInputElement).value = this.button.body || '';
    else this.button[key] = value;
  }

  createButton() {
    const button: IWAButton = { body: this.button.body };
    const url = this.button.url;
    if (url) {
      const number = this.formatNumber(url);
      if (number) button.number = number;
      else button.url = this.formatUrl(url);
    }
    this.sendService.addButton(button);
  }

  formatNumber(text: string): string | undefined {
    const numUrl = text?.replace(/([-+() ])/g, '');
    const isNumber = parseInt(numUrl, 10);
    if (!isNumber) return;
    return numUrl.toString();
  }

  formatUrl(text: string): string {
    const httpsPrefix = 'https://';
    const httpPrefix = 'http://';
    if (text.startsWith(httpsPrefix) || text.startsWith(httpPrefix)) {
      return text;
    }
    return httpsPrefix + text;
  }
}
