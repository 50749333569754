import { Injectable } from '@angular/core';
declare let fbq: (
  event: string,
  eventName: string,
  value?: { [key: string]: any },
) => void;
@Injectable({
  providedIn: 'root',
})
export class FbPixelService {
  track(eventName: string, value?: { [key: string]: any }) {
    if (!value) fbq('track', eventName);
    else fbq('track', eventName, value);
  }
}
