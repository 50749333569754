import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'libs/shared/src/lib/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  #aiMessage = '';

  constructor(private http: HttpClient) {}

  async formatText(body: string): Promise<string> {
    const response = await firstValueFrom(
      this.http.post<any>(environment.cloudFunctionsApiUrl + '/formatMessage', {
        message: body,
      }),
    );
    const message = response.formattedMessage[0].text.trim();
    this.#aiMessage = message;
    return message;
  }

  checkIsAiMessage(message: string) {
    return getTextSimilarity(message, this.#aiMessage) > 60 ? 1 : 0;
  }
}

function getTextSimilarity(str1: string, str2: string) {
  // Handle edge cases
  if (!str1 || !str2) return 0;
  if (str1 === str2) return 100;

  // Convert to lowercase and split into words
  const words1 = str1
    .toLowerCase()
    .split(/\W+/)
    .filter((word) => word.length > 0);
  const words2 = str2
    .toLowerCase()
    .split(/\W+/)
    .filter((word) => word.length > 0);

  // Create word frequency maps
  const freqMap1 = new Map();
  const freqMap2 = new Map();

  // Count word frequencies for first text
  for (const word of words1) {
    freqMap1.set(word, (freqMap1.get(word) || 0) + 1);
  }

  // Count word frequencies for second text
  for (const word of words2) {
    freqMap2.set(word, (freqMap2.get(word) || 0) + 1);
  }

  // Get unique words from both texts
  const uniqueWords = new Set([...freqMap1.keys(), ...freqMap2.keys()]);

  // Calculate cosine similarity
  let dotProduct = 0;
  let norm1 = 0;
  let norm2 = 0;

  for (const word of uniqueWords) {
    const freq1 = freqMap1.get(word) || 0;
    const freq2 = freqMap2.get(word) || 0;
    dotProduct += freq1 * freq2;
    norm1 += freq1 * freq1;
    norm2 += freq2 * freq2;
  }

  // Avoid division by zero
  if (norm1 === 0 || norm2 === 0) return 0;

  // Calculate final similarity percentage
  const similarity = (dotProduct / (Math.sqrt(norm1) * Math.sqrt(norm2))) * 100;
  return similarity;
}
